import { createContext, useState, useEffect, FC, useMemo, useCallback } from 'react';

export type ThemeModeType = 'light' | 'dark';

interface ThemeProviderProps {
  initialTheme?: ThemeModeType;
  children: React.ReactNode;
}

interface ThemeContextType {
  theme: ThemeModeType;
  setTheme: (theme: ThemeModeType) => void;
}

export const ThemeContext = createContext<ThemeContextType>({
  setTheme: () => {},
  theme: 'light',
});

export const ThemeProvider: FC<ThemeProviderProps> = ({ initialTheme, children }) => {
  const [theme, setTheme] = useState(getInitialTheme);

  const rawSetTheme = (rawTheme: ThemeModeType) => {
    const root = window.document.documentElement;
    const isDark = rawTheme === 'dark';

    root.classList.remove(isDark ? 'light' : 'dark');
    root.classList.add(rawTheme);

    sessionStorage.setItem('color-theme', rawTheme);
    setTheme(rawTheme);
  };

  if (initialTheme) {
    rawSetTheme(initialTheme);
  }

  const listenerChangeTheme = useCallback(() => {
    window.matchMedia('(prefers-color-scheme: dark)').addEventListener('change', function (e) {
      const colorScheme = e.matches ? 'dark' : 'light';

      rawSetTheme(colorScheme);
    });
  }, []);

  useEffect(() => {
    rawSetTheme(theme);
  }, [theme]);

  useEffect(() => {
    listenerChangeTheme();

    return () => listenerChangeTheme();
  }, [listenerChangeTheme]);

  return useMemo(
    () => <ThemeContext.Provider value={{ theme, setTheme }}>{children}</ThemeContext.Provider>,
    [theme, children],
  );
};

const getInitialTheme: () => ThemeModeType = () => {
  if (typeof window !== 'undefined' && window.localStorage) {
    const storedPrefs = window.sessionStorage.getItem('color-theme');
    if (typeof storedPrefs === 'string') {
      return storedPrefs as ThemeModeType;
    }

    const userMedia = window.matchMedia('(prefers-color-scheme: dark)');
    if (userMedia.matches) {
      return 'dark' as ThemeModeType;
    }
  }

  return 'light' as ThemeModeType; // light theme as the default;
};
