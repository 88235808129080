import '@/styles/globals.css';
import { appWithTranslation } from 'next-i18next';
import type { AppProps } from 'next/app';
import { Provider } from 'react-redux';
import { persistor, store } from '../store/store';
import { ThemeProvider } from '@/components/shared/utility/theme/ThemeContext';
import { fontLexend, fontOpen } from '@/lib/loadFont';
import { PersistGate } from 'redux-persist/integration/react';
import dynamic from 'next/dynamic';

const DynamicNextNProgress = dynamic(() => import('nextjs-progressbar'), {
  ssr: false,
});

function App({ Component, pageProps }: AppProps) {
  return (
    <>
      <style jsx global>
        {`
          :root {
            --font-lexend: ${fontLexend.style.fontFamily};
            --font-open: ${fontOpen.style.fontFamily};
          }
        `}
      </style>

      <Provider store={store}>
        <PersistGate loading={null} persistor={persistor}>
          {() => (
            <ThemeProvider>
              <DynamicNextNProgress
                key={'next-progress-bar'}
                color="#A91314"
                startPosition={0.8}
                options={{ showSpinner: false }}
                height={2}
              />
              <Component {...pageProps} />
            </ThemeProvider>
          )}
        </PersistGate>
      </Provider>
    </>
  );
}

export default appWithTranslation(App);
