import { Lang } from '@/models/interfaces/pages/root';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';

interface UtilityState {
  menuOpen: boolean;
  lang: Lang | null;
}

const initialState: UtilityState = {
  menuOpen: false,
  lang: null,
};

export const utilitySlice = createSlice({
  name: 'utility',
  initialState: initialState,
  reducers: {
    openMenu: (state) => {
      state.menuOpen = true;
    },
    closeMenu: (state) => {
      state.menuOpen = false;
    },
    setLinkLang: (state, action: PayloadAction<Lang>) => {
      state.lang = action.payload;
    },
  },
});

export const { closeMenu, openMenu, setLinkLang } = utilitySlice.actions;

export default utilitySlice.reducer;
