import { configureStore } from '@reduxjs/toolkit';
import utilityReducer from './slices/utilitySlice';
import wishlistReducer from './slices/wishlistSlice';
import {
  FLUSH,
  PAUSE,
  PERSIST,
  PURGE,
  REGISTER,
  REHYDRATE,
  persistReducer,
  persistStore,
} from 'redux-persist';
import storage from 'redux-persist/lib/storage';

import thunk from 'redux-thunk';

const persistWishListConfig = {
  key: 'wishlist',
  storage: storage,
};

const wishlistPersistReducer = persistReducer(persistWishListConfig, wishlistReducer);

export const store = configureStore({
  reducer: {
    utility: utilityReducer,
    wishlist: wishlistPersistReducer,
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: false,
    }).prepend(thunk),
});

export const persistor = persistStore(store);

export type AppDispatch = typeof store.dispatch;
export type RootState = ReturnType<typeof store.getState>;
