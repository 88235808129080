import { CardType } from '@/models/types/types';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';

interface StateType {
  wishlist: CardType[];
}

const initialState: StateType = {
  wishlist: [],
};

const whishlistSlice = createSlice({
  name: 'whishlist',
  initialState: initialState,
  reducers: {
    toggleToWishList: (state, action: PayloadAction<CardType>) => {
      const card = state.wishlist.find((card) => card.id === action.payload.id);
      if (card) {
        state.wishlist = state.wishlist.filter((card) => card.id !== action.payload.id);
      } else {
        state.wishlist.push(action.payload);
      }
    },
  },
});

export const { toggleToWishList } = whishlistSlice.actions;

export default whishlistSlice.reducer;
